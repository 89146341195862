<template>
	<div class="home max-w-screen-xl mx-auto">
		<div class="flex flex-row">
			<button class="mt-4 mb-4 mr-2 bg-cyan-600 hover:bg-cyan-900 transition ease-in" @click.prevent="displayAddTransaction" >Add Transaction</button>
		</div>
		<transition name="fade-slide" appear >
			<div class="mt-4 mb-4 p-5 border border-cyan-600 bg-boxdark" v-if="newTransactionBoxVisible">
				
				<div class="flex flex-col">
					<div class="flex flex-col md:flex-row gap-y-4">
						<div class="px-2">
							<label for="Name">Name</label>
							<input type="text" id="Name" placeholder="Name" v-model="newTransaction.Name">
						</div>
						<div class="px-2">
							<label for="Description">Description</label>
							<input type="text" id="Description" placeholder="Description" v-model="newTransaction.Description">
						</div>
						<div class="px-2">
							<label for="daDatete">Date</label>
							<input type="date" id="Date" placeholder="Date" v-model="newTransaction.Date">
						</div>
						<div class="px-2">
							<label for="Price">Price</label>
							<input type="number" id="Price" placeholder="Price" v-model="newTransaction.Price">
						</div>
						<div class="px-2">
							<label for="Type">Type</label>
							<select v-model="newTransaction.Type" id="Type">
								<option value="Income">Income</option>
								<option value="Outcome">Outcome</option>
							</select>
						</div>
					</div>
			
						
					<div class="flex mt-4 mb-4 justify-end">
						<button class="bg-red-700 hover:bg-red-950 transition ease-in mr-2" @click="hideTransactionBox">Cancel</button>
						<button class="bg-cyan-600 hover:bg-cyan-900 transition ease-in" @click.prevent="addTransaction">Add Transactions</button>
					</div>
				</div>
				
			</div>
		</transition>
		<div class="text-sm">
			<div v-if="enhancedData.length > 0">

				<div v-for="year in sortedYears" :key="year" class="year-section">
					<div v-if="groupedData[year]">
						<div class="text-2xl bg-boxdark flex flex-col md:flex-row w-full p-2 items-center  mt-8 mb-2 justify-between">
							<div><span class="grow">{{ year }}</span></div>
							<div>
								<span class="text-sm italic mr-5 flex-none text-white ">Total :</span>
								<span class="text-sm italic mr-5 flex-none text-green-700">{{ (groupedData[year].totalIncomes).toFixed(2) }} $</span>
								<span class="text-sm italic mr-5 flex-none text-white">-</span>
								<span class="text-sm italic mr-5 flex-none text-red-700">{{ (groupedData[year].totalOutcomes).toFixed(2) }} $</span>
								<span class="text-sm italic mr-5 flex-none text-white">=</span>
								<span class="text-sm italic mr-5 flex-none text-white">{{ (groupedData[year].totalIncomes - groupedData[year].totalOutcomes).toFixed(2) }} $</span>
							</div>
						</div>

						<div v-for="(monthData, month) in groupedData[year].months" :key="month" class="month-section">
							<div class="text-lg bg-boxdark flex flex-col md:flex-row w-full p-2 items-center mt-2 justify-between">
								<div><span class="grow text-left">{{ month }}</span></div>
								<div>
									<span class="text-sm italic mr-5 flex-none text-white">Total :</span>
									<span class="text-sm italic mr-5 flex-none text-green-700">{{ monthData.totalIncomes.toFixed(2) }} $</span> 
									<span class="text-sm italic mr-5 flex-none text-white">-</span>
									<span class="text-sm italic mr-5 flex-none text-red-700">{{ monthData.totalOutcomes.toFixed(2) }} $</span>
									<span class="text-sm italic mr-5 flex-none text-white">=</span>
									<span class="text-sm italic mr-5 flex-none text-white">{{ (monthData.totalIncomes - monthData.totalOutcomes).toFixed(2) }} $</span>
								</div>
							</div>

							<div v-for="(item, index) in monthData.data" :key="index">
								<div class="grid grid-cols-[1fr_auto_auto_auto_1px] md:grid-cols-5 items-center h-auto md:h-16 border border-boxdark text-base md:text-lg" :class="{'text-green-700': item.Type === 'Income', 'text-red-700': item.Type === 'Outcome'}">
									<div class="col-span-full md:col-auto flex items-center p-1 md:p-2 border-r border-boxdark">{{ item.Name }}</div>
									<div class="flex items-center p-1 md:p-2 border-r border-boxdark">{{ item.Description }}</div>
									<div class="flex items-center justify-center p-1 md:p-2 border-r border-boxdark text-xs md:text-lg">{{ formatToDateString(item.Date) }}</div>
									<div class="flex items-center justify-center p-1 md:p-2 border-r border-boxdark text-xs md:text-lg">{{ item.Price.toFixed(2) }} $</div>
									<div class="flex items-center justify-center p-1 md:p-2 w-[50px] md:w-auto">
										<button @click="openDelete(item.id)">
											<svg class="w-[14px] md:w-[25px]"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 12L14 16M14 12L10 16M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				</div>
			<div v-else>
				<p>No transactions found or loading...</p>
			</div>
		</div>

		<!-- Main modal -->
		<div id="default-modal" tabindex="-1" class="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50" :class="{'hidden' : !deleteVisible}">
			<div class="relative p-4 w-full max-w-2xl max-h-full">
				<!-- Modal content -->
				<div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
					<!-- Modal header -->
					<div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
						<h3 class="text-xl font-semibold text-gray-900 dark:text-white">
							Veux tu vraiment supprimer cette transaction ?
						</h3>
					</div>
					<!-- Modal footer -->
					<div class="flex  justify-end items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
						<button @click="deleteVisible=false"  type="button" class="py-2.5 px-5 ms-3 mr-4 text-sm font-medium  bg-red-700 rounded-lg  hover:bg-red-900 text-white ">Decline</button>
						<button @click="deleteTransaction"  type="button" class="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">I accept</button>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup>
	import { ref, onMounted, defineComponent, computed  } from 'vue';
	import { collection, getDocs, query, deleteDoc, doc, orderBy, addDoc,Timestamp   } from "firebase/firestore";
	import { db } from "@/firebase"; // Adjust the import as per your setup


	defineComponent({
		name: 'HomeView'
	});
	
	const transactions = ref([]);
	const newTransaction = ref({Type: 'Income'})
	const newTransactionBoxVisible = ref(false)
	const deleteVisible = ref(false);
	const deleteTransactionId = ref(null);
	
	const fetchTransactions = async () => {
		try {
			const transactionsQuery = query(
			collection(db, "transactions"),
			orderBy("Date", "desc") // Ensure we're using "Date" if that's your field
			);

			const querySnapshot = await getDocs(transactionsQuery);

			transactions.value = querySnapshot.docs.map(doc => ({
				...doc.data(),
				id: doc.id, // Optionally include the document ID
			}));
		} catch (error) {
			console.error("Error fetching outcomes:", error);
		}
	};
	const sortedYears = computed(() => {
		return Object.keys(groupedData.value)
			.map(year => parseInt(year)) // Convert to integers for correct sorting
			.sort((a, b) => b - a); // Sort in descending order
	});
	const enhancedData = computed(() =>
		(transactions.value || []).map(item => ({
			...item,
			Date: new Date(item.Date.seconds * 1000), // Convert seconds to milliseconds
		}))
	);
	const openDelete = (id) => {
		deleteVisible.value = true;
		deleteTransactionId.value = id;
	}
	const groupedData = computed(() => {
		return enhancedData.value.reduce((acc, item) => {
			if (!item.Date || !item.Price) {
			return acc; // Skip invalid entries with missing Date or Price
			}

			const year = item.Date.getFullYear();
			const month = item.Date.toLocaleString("default", { month: "long" });

			// Ensure the year exists
			if (!acc[year]) {
			acc[year] = { totalIncomes: 0, totalOutcomes: 0, months: {} };
			}

			// Ensure the month exists
			if (!acc[year].months[month]) {
			acc[year].months[month] = { totalIncomes: 0, totalOutcomes: 0, data: [] };
			}

			// Update month total and add data
			if (item.Type === "Income") {
			acc[year].totalIncomes += item.Price || 0;
			acc[year].months[month].totalIncomes += item.Price || 0;
			acc[year].months[month].data.push(item);
			} else if (item.Type === "Outcome") {
			acc[year].totalOutcomes += item.Price || 0;
			acc[year].months[month].totalOutcomes += item.Price || 0;
			acc[year].months[month].data.push(item);
			}

			return acc;
		}, {});
	});
	const addTransaction = async () => {
		if (newTransaction.value.Name && newTransaction.value.Description && newTransaction.value.Date && newTransaction.value.Price) {
		
			const date = new Date(newTransaction.value.Date+"T00:00:00")
			await addDoc(collection(db, "transactions"), {
				...newTransaction.value,
				Date: Timestamp.fromDate(date), // Store as Firestore Timestamp
			});

			newTransaction.value = { Name: "", Description: "", Type: 'Income', Date: new Date().toISOString().split('T')[0], Price: null };
			newTransactionBoxVisible.value = false;

			await fetchTransactions();// Check if all fields are filled
		}
		
	};
	
	const formatToDateString=(dateInput)=> {
		// Parse the date input into a Date object
		const date = new Date(dateInput);

		// Extract day, month, and year
		const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
		const year = date.getFullYear();

		// Return the formatted date
		return `${day}/${month}/${year}`;
	}
	
	const deleteTransaction = async () => {
		await deleteDoc(doc(db, "transactions", deleteTransactionId.value));
		await fetchTransactions(); // Refresh list after deletion
		deleteVisible.value = false;
	};
	
	
	const displayAddTransaction = () => {
		newTransaction.value = {Type: 'Income', Date: new Date().toISOString().split('T')[0]}
		newTransactionBoxVisible.value = true;
	};
	
	const hideTransactionBox = () => {
		newTransactionBoxVisible.value = false;
	};

	onMounted(async () => {
		await fetchTransactions()
	});
</script>
<style scoped>
/* Transition styles */
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: all 0.5s ease;
}

.fade-slide-enter-from {
  opacity: 0;
  transform: translateY(-20px);
}

.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
</style>