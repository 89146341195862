<template>
	<div class="container mx-auto">
		<!-- TODO: Add a better Header -->
		<div class="flex text-center mt-4 mb-5 items-center max-w-screen-xl mx-auto">
			<h1 class="text-title-xl grow flex items-center">
				<span class="rounded-full overflow-hidden w-10 h-10 inline-block mr-2"><img src="@/assets/favicon-96x96.png" alt=""></span>
				Rendom_Tattoo
			</h1>
			<button @click="logout" v-if="isAuthenticated">Logout</button>
		</div>
			<!-- TODO: Add a page for statistics-->

			<!-- <nav  v-if="$store.state.user">
				<router-link to="/">Home</router-link> |
				<router-link to="/about">About</router-link>
				
			</nav> -->
		<router-view/>
		
	</div>
</template>

<script>
import { onBeforeMount, computed } from 'vue';
import { useStore } from 'vuex';
export default {
	setup() {
		const store  = useStore();
		onBeforeMount(() => {
			store.dispatch('fetchUser');
		});

		// Computed property for checking if the user is authenticated
		const isAuthenticated = computed(() => store.getters.isAuthenticated);

		// Method to trigger logout
		const logout = () => {
			store.dispatch('logout');
		};

		// Return the values you want to expose to the template
		return {
			isAuthenticated,
			logout
		};
	}
}
</script>

