import { createStore } from 'vuex'
import { auth } from '../firebase'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import router from '../router'

export default createStore({
	state: {
		user: null
	},
	mutations: {
		SET_USER(state, user) {
			state.user = user
		},
		CLEAR_USER(state) {
			state.user = null
		}
	},
	actions: {
		// The login action
		async login({ commit }, details) {
			const { email, password } = details
			try {
			await signInWithEmailAndPassword(auth, email, password)
			commit('SET_USER', auth.currentUser)  // Commit user to the store
			router.push('/')  // Redirect after login
			} catch (error) {
			switch (error.code) {
				case 'auth/user-not-found':
				alert("User not found")
				break
				case 'auth/wrong-password':
				alert("Wrong password")
				break
				default:
				alert("Error")
			}
			}
		},

		// The register action
		async register({ commit }, details) {
			const { email, password } = details
			try {
			await createUserWithEmailAndPassword(auth, email, password)
			commit('SET_USER', auth.currentUser)  // Commit user to the store
			router.push('/')  // Redirect after registration
			} catch (error) {
			switch (error.code) {
				case 'auth/email-already-in-use':
				alert("Email already in use")
				break
				case 'auth/invalid-email':
				alert("Invalid email")
				break
				case 'auth/operation-not-allowed':
				alert("Operation not allowed")
				break
				case 'auth/weak-password':
				alert("Weak password")
				break
				default:
				alert("Error")
			}
			}
		},

		// The logout action
		async logout({ commit }) {
			await signOut(auth)
			commit('CLEAR_USER')  // Clear the user from the store
			router.push('/login')  // Redirect to login after logout
		},

		// Fetch user on app load or auth state change
		fetchUser({ commit }) {
			auth.onAuthStateChanged(async user => {
				if (user === null) {
					commit('CLEAR_USER')  // Clear user if not logged in
				} else {
					commit('SET_USER', user)  // Set user if logged in
					if (router.isReady() && router.currentRoute.value.path === "/login") {
					router.push('/')  // Redirect to home if logged in and on login page
					}
				}
			})
		}
	},
	getters: {
		isAuthenticated(state) {
			return state.user !== null  // Return true if the user is authenticated
		}
	}
})
